.Buttons {
  width: 100%;
  margin:20px 0px 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Buttons .Row {
  width: 80%;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px dotted #999999;
  margin-bottom: 10px;
}
.Buttons .Row div {
  margin: 10px;
}
