.DesignSystem .TypographyContainer .Other {
  width: 40%;
}
.DesignSystem .TypographyContainer .Titles {
  display: flex;
  width: 40%;
}
.DesignSystem .ButtonsContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  border-top: 1px #999999 solid;
}
.DesignSystem .CustomInputContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  border-top: 1px #999999 solid;
  border-bottom: 1px #999999 solid;
  padding: 20px;
  margin: 20px 0px 20px 0px;
  background-color: rgba(0, 0, 0, 0.03);
}
.DesignSystem .OtherInputsContainer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px #999999 solid;
}
.DesignSystem .Loaders {
  min-height: 150px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  border-bottom: 1px #999999 solid;
}
.DesignSystem .Extras {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #999999;
  background-color: rgba(0, 0, 0, 0.03);
}
.DesignSystem .Table {
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-bottom: 20px;
  border-bottom: 1px solid #999999;
}
.DesignSystem .Alerts {
  width:100%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.DesignSystem .Card {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.03);
}
.DesignSystem .TypographyContainer {
  background-color: rgba(0, 0, 0, 0.03);
  width:100%;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
}
.DesignSystem .ButtonsContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  border-top: 1px #999999 solid;
}
.DesignSystem .CustomInputContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  border-top: 1px #999999 solid;
  border-bottom: 1px #999999 solid;
  padding: 20px;
  margin: 20px 0px 20px 0px;
  background-color: rgba(0, 0, 0, 0.03);
}
.DesignSystem .OtherInputsContainer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px #999999 solid;
}
.DesignSystem .Loaders {
  min-height: 150px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  border-bottom: 1px #999999 solid;
  background-color: rgba(0, 0, 0, 0.03);
}
.DesignSystem .Extras {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #999999;
  background-color: rgba(0, 0, 0, 0.03);
}
.DesignSystem .Card .CardFirstRow {
  display: flex;
}
.DesignSystem .CardsAndAlerts {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #999999;
}
.DesignSystem .DatePickers {
  width: 100%;
  padding: 20px 0px 20px 0px;
  border-bottom: 1px solid #999999;
}
